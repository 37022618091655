<template>
  <div>
    <vue-apex-charts
      ref="barChart"
      height="200"
      :options="barChart.chartOptions"
      :series="series"
      class="max-500 mx-auto"
    />
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: ['barData', 'barColors', 'barCategories'],
  mounted() {
    //if(this.$route.name == "score") {
      this.$refs.barChart.updateSeries([{
        data: this.barData
      }])
      
      this.$refs.barChart.updateOptions({
        colors: this.barColors,
        xaxis: {
          categories: this.barCategories
        }
      })
    // }
  },
  data() {
    return {
      series: [{
        data: []
      }],
      barChart: {
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false
            },
          },
          tooltip: {
            enabled: false,
          },
          colors: ['#7eb39d', '#7eb39d', '#7eb39d'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              rangeBar: false,
              dataLabels: {
                position: 'top',
              },
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: 40,
            formatter: function (val, opts) {
                return val + "/10"
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Montserrat, Helvetica sans-serif',
                fontWeight: 'normal',
                colors: ['#000','#000','#000']
            },
          },
          xaxis: {
            categories: ["Biological", "Psychological", "Social"],
            labels: {
              show: false
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            max: 10,
          },
          legend: {
            show: false
          }
        },
      },
    };
  },
  watch: {
    $props: {
      handler(val) {
          if(this.$refs.barChart != undefined) {
            this.series[0].data = val.barData;

              this.$refs.barChart.updateSeries([{
                data: this.barData
              }]).catch(error => {
                // this is just to supress the error in the console
                // if this catch block is removed then whenever the my-hub or some of the sub pages load for the first time, an error will pop up
              });
              
              this.$refs.barChart.updateOptions({
                colors: val.barColors,
                xaxis: {
                  categories: val.barCategories
                }
              })          
          }
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style scoped>
  .max-500 {
    max-width: 500px;
  }
</style>