<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Header -->
      <div class="d-flex justify-content-between">
        <h4>Profiles that you follow</h4>
        <b-button variant="primary" @click="inviteFriends">Invite friends</b-button>
      </div>
      <div class="mb-3">
        <small>
          <what-does-this-mean type="users-you-follow" />
        </small>
      </div>
      
      <!-- Table Top -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <!-- Per Page -->
        <div cols="6" md="6">
          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-input
              v-model="searchUser"
              type="text"
              size="sm"
              placeholder="Search profiles"
              required
          />
        </div>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <!-- Column: User -->
      <template #cell(user)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.followed_u.username }}
        </span>
      </template>

      <!-- Column: Follow since -->
      <template #cell(follow_since)="data">
        <span
          class="text-capitalize"
        >
          {{ data.item.created_at | prepareDate }}
        </span>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-badge
            pill
            variant="light-primary"
            class="cursor-pointer"
            @click="unfollowUser(data)">
          <feather-icon icon="UserMinusIcon" size="14" /> 
          Unfollow
        </b-badge>
      </template>
      
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <div v-if="data.length == 0" class="mb-1">
        <span class="mhc-link-underline" @click="inviteFriends()">
          Start building your community by inviting friends and family.
        </span>
      </div> 

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import EventBus from '../../main.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WhatDoesThisMean from "@/components/modals/WhatDoesThisMean.vue"

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
    WhatDoesThisMean
  },
  data() {
    return {
      data: [],
      searchUser: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "user", label: "User" },
        { key: "follow_since", label: "Following since", class: "text-center text-nowrap" },
        { key: "action", label: "Actions" }
      ],
    };
  },
  computed: {
    filteredList() {
      let searchTerm = this.searchUser;
      let countUsers = 0

      return this.data.filter(user => {
          if(user.followed_u.username == null) {
              user.followed_u.username = ""
          }

          if(user.followed_u.username.toLowerCase().includes(searchTerm.toLowerCase())) {
              // Update total rows
              countUsers++
              this.totalRows = countUsers
              
              return user;
          }
      });
    }
  },
  methods: {
    unfollowUser(data) {
      // Show modal
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to unfollow ' + data.item.followed_u.username + "?", {
        title: 'Are your sure?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        // On 'yes' unfollow user
        if(value == true) {
          this.$http.delete("/api/auth/user/follow-requests/unfollow/" + data.item.id)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: "User unfollowed!",
                  icon: "CheckSquareIcon",
                  variant: "success",
              },
            });
            // Get all the users you follow
            this.getFollowings();              
          }).catch((error) => {
            console.log(error)

            this.$toast({
              component: ToastificationContent,
              props: {
                  title: "There was an error unfollowing the user!",
                  icon: "AlertCircleIcon",
                  variant: "danger",
              },
            });
          });
        }
      })
    },
    getFollowings() {
      // Request to get all the users you follow
      this.$http.get("/api/auth/user/followings")
        .then((res) => {
          if(res != undefined) {
            this.data = res.data.followings

            // Set the initial number of items
            this.totalRows = this.data.length
          }
        })
        .catch((err) => {
          console.log(err);

          this.$toast({
            component: ToastificationContent,
            props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
            },
          });
        });
    },
    // Open Invite friends modal
    inviteFriends() {
      EventBus.$emit('inviteFriends')
    }
  },
  mounted() {
    // Get all the users you follow
    this.getFollowings();
  },
  watch:{
    data() {
      this.totalRows = this.data.length
    },
  }
};
</script>

<style scoped>
  .card-header {
    display: none;
  }
</style>