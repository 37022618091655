<template>
  <div>
    <span class="cursor-pointer mhc-link-blue-color" @click="openModal">
      <span
        v-if="
          (type === 'details-circle-chart' && message != undefined) ||
          (type === 'my-hub' && message != undefined) ||
          (type === 'questionnaire-submissions' && message != undefined)
        "
        class="text-underline"
        >More info</span
      >
      <span
        v-else-if="
          type === 'users-same-trait' ||
          type === 'users-you-follow' ||
          type === 'users-follow-you' ||
          type === 'blocked-users'
        "
        >What is this?</span
      >
      <span v-else>What does this mean?</span>
    </span>

    <b-modal id="wdtm-modal" ref="wdtm-modal" hide-footer static centered size="lg">
      <template #modal-title> What does it mean? </template>
      <div class="d-block py-2 px-1 color-gray">
        <div v-if="type === 'home-circle-chart'">
          <div>
            Your overall mental health score is an indicator of your general
            mental health based on your completed mental health checks.
          </div>
          <div class="mt-1">
            Your general mental health score is out of ten. The higher your
            score, the better we perceive your mental health to be.
          </div>
        </div>

        <div v-if="type === 'home-circle-chart-bottom'">
          <h4 class="text-center mb-2">
            Biopsychosocial Interactions Explained
          </h4>
          <div class="mt-2">
            <div class="font-weight-bold">Biological</div>
            <div class="mt-1">
              Biological factors include bodily functions and physical aspects
              of our life. This includes but is not exclusive to, your gender,
              sexuality, injuries, disabilities, medications, genetics, diet,
              recreational drugs/alcohol and medical history. These factors
              affect every aspect of our physical, social and mental life. For
              example, if we are not getting enough sleep, our
              reactions/responses/emotions will be impacted by this.
              Additionally, if we are afflicted by a disability, we will face
              unique challenges, and this will have a ripple effect on our
              psychological and social functioning. It is important to factor
              physical factors into our own mental health assessment, so we can
              begin to address every issue which is contributing to the umbrella
              of 'mental health distress'.
            </div>
          </div>
          <div class="mt-2">
            <div class="font-weight-bold">Psychological</div>
            <div class="mt-1">
              Psychological factors include our beliefs, attitudes, education,
              perceptions, emotions and feelings towards a situation. This
              situation can be a mental/physical illness or any other situation
              we find ourselves in. If we approach a situation with a belief
              that we will fail or we don't have the capabilities to deal with
              it, then we find that we can become distressed. Similarly, if we
              believe our self-worth is defined by how successful our career is,
              if a social situation shatters that career, our self-worth becomes
              shattered at the same time. These core beliefs are part of our
              psychology which has been shaped over our entire life and impacted
              by our biology and social experiences. Changing these core beliefs
              and attitudes is the job of therapeutic techniques. To treat
              mental distress, we need to have a healthy psychology of beliefs,
              attitudes and self-esteem.
            </div>
          </div>
          <div class="mt-2">
            <div class="font-weight-bold">Social</div>
            <div class="mt-1">
              Social factors include finances, support networks, culture and
              access to services. Social factors are often affected by
              biological factors such as race/gender/sexuality. This is seen in
              situations of discrimination. This can then subsequently create a
              belief in our psychology which is negative and leads to mental
              distress.
            </div>

            <div class="mt-1">
              Similarly, lack of access to the correct medical resources can
              enhance a medical condition we have, which then impacts us
              socially and ultimately psychologically.
            </div>

            <div class="mt-1">
              Struggling with social situations for whatever reason can that
              exasperate biological and/or psychological factors and become a
              negative distressing cycle. This is why it is important to
              consider any social contributors within our life which are having
              a negative impact on our mental health, whether directly or
              indirectly.
            </div>
          </div>
        </div>
        <div
          class="font-weight-normal"
          v-if="type === 'details-circle-chart' && message != undefined"
        >
          <h2>Hi {{name}}, based on your responses to <span class="color-mhc-light">{{ subcategory }}</span> mental health check,</h2>          
          <p class="font-size-20" v-if="message['Questionnaire Score']">
            your score is {{ message["Questionnaire Score"].section_score }}
          </p>

          <hr class="mhc-divider m-auto" />
          
          <h1 class="pt-1" v-if="message['Questionnaire Score']">{{ message["Questionnaire Score"].section_score }}</h1>
          <p>out of 10</p>
          <hr class="mhc-divider m-auto" />
      
          <div class="mt-3">
            <div class="text-center mb-1" v-if="professional != null">
              <img :src="professional.profile_image_url" class="rounded-image-100px rounded-circle" />
              <p class="mb-0 mt-05">{{professional.first_name}} {{professional.last_name}}</p>
              <small class="text-gray">{{professional.qualifications}}</small>
            </div>
            
            <div class="triangle" v-if="professional != null"></div>
          </div>

          <div :class="{ 'professional-cloud-opinion': professional != null}">
            <div v-if="message['Questionnaire Score']">
              <p
                class="pt-1 font-weight-normal"
                v-for="(msg, index) in message['Questionnaire Score'].messages"
                :key="index"
                v-html="msg"
              ></p>
            </div>

            <div v-if="message['Section A']">
              <h3
                class="pt-1 font-weight-normal"
                v-if="message['Section A'].messages.length > 0"
              >
                Additional information
              </h3>
              <p
                class="pt-1 font-weight-normal"
                v-for="(msg, index) in message['Section A'].messages"
                :key="(index + 1) * 56"
                v-html="msg"
              ></p>
            </div>
          </div>

        </div>

        <div
          class="font-weight-normal"
          v-if="type === 'details-emotions-my-hub' && message != undefined"
        >
          <div class="text-center"> 
            <h2 class="text-center">Hi {{name}}, based on your overall responses to <span class="color-mhc-light"> I feel {{ subcategory }}</span> mental health check,</h2>          
            <p class="font-size-20">
              your average score is {{ score }}
            </p>

            <hr class="mhc-divider m-auto" />
            
            <h1 class="pt-1">{{ score }}</h1>
            <p>out of 10</p>
            <hr class="mhc-divider m-auto" />
          </div>
          <div class="mt-3">
            <div class="text-center mb-1" v-if="professional != null">
              <img :src="professional.profile_image_url" class="rounded-image-100px rounded-circle" />
              <p class="mb-0 mt-05">{{professional.first_name}} {{professional.last_name}}</p>
              <small class="text-gray">{{professional.qualifications}}</small>
            </div>
            
            <div class="triangle" v-if="professional != null"></div>
          </div>

          <div :class="{ 'professional-cloud-opinion': professional != null }">
            <p
              class="pt-1 font-weight-normal"
              v-html="message.text">
              </p>
          </div>
        </div>

        <p class="font-weight-normal" v-if="type === 'home-line-chart'">
          This chart helps you visually track the state of your overall mental
          health over time based on your completed mental health checks.
        </p>

        <div v-if="type === 'questionnaire-circle-chart'">
          <p class="font-weight-normal">
            Your mental health trait score for {{ subcategory }} is an indicator
            of how well you are coping with this life situation.
          </p>
          <p class="font-weight-normal">
            Please note – If you are consistently worried about your mental
            health trait score, we would always advise that you seek medical
            help.
          </p>
        </div>

        <div v-if="type == 'emotional-score-explanation'">
          <p class="font-weight-normal">
            This feeling index helps you identify emotions you may be experiencing and the extent to which you may be feeling these emotions
          </p>
        </div>

        <div v-if="type == 'wellbeing-score-explanation'">
          <p class="font-weight-normal">
            This index helps you identify your wellbeing traits based on your wellbeing mental health check.
          </p>
        </div>

        <p
          class="font-weight-normal"
          v-if="type === 'questionnaire-line-chart'"
        >
          This chart helps you visually track your mental health progress based
          on your completed {{ subcategory }} mental health check.
        </p>

        <!-- Home Recommended Toolkits -->
        <p class="font-weight-normal" v-if="type === 'home-recommended-toolkits'">
          These videos and other helpful content have been curated for you based on your completed mental health checks.
        </p>

        <!-- What is this -->
        <p class="font-weight-normal" v-if="type === 'users-same-trait'">
          Your community is where you can share experiences, show and receive
          support from profiles you share similar experiences with. Select
          ‘Follow’ and start growing your support community sharing, sending and
          receiving the support you need.
        </p>

        <p class="font-weight-normal" v-if="type === 'users-you-follow'">
          These are profiles that accepted your request to join their support
          community.
        </p>

        <p class="font-weight-normal" v-if="type === 'users-follow-you'">
          These are profiles you have accepted into your support community.
        </p>

        <p class="font-weight-normal" v-if="type === 'blocked-users'">
          These are profiles that you have blocked from having access to your
          support community.
        </p>

        <div
          v-if="
            (type === 'my-hub' || type === 'questionnaire-submissions') &&
            message != undefined
          "
        >

          <h3 class="pb-1 font-weight-normal text-center" v-if="type== 'my-hub'">Your overall mental health trait score</h3>
          <h3 class="pb-1 font-weight-normal text-center" v-if="type== 'questionnaire-submissions'">Your {{ subcategory }} trait score</h3>

          <pie-chart :data="circleChartData" :chartColor="chartColor"  class="mb-2" />

          <bar-chart v-if="barColors.length > 0" :barData="barChartData" :barColors="barColors" :barCategories="barCategories" />

          <div>
            <div v-if="message == 10">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is Great
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>
                , you are in a GREAT mental headspace which is extremely positive.
              </p>
              <p class="pb-1 font-weight-normal">
                It appears that you can manage stressful and negative situations
                brilliantly, minimising the impact of these situations on your
                general mental health and well-being.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 10 out of 10 is a great indicator that you have
                implemented and maintained effective strategies to maintain great
                mental health and well-being. This is very positive, and we would
                encourage you to keep going.
              </p>
            </div>
            <div v-if="message < 10 && message >= 9">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is Very Good
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are in a VERY GOOD
                mental headspace which is very positive.
              </p>
              <p class="pb-1 font-weight-normal">
                It appears that you are in control of your emotions and managing
                your mental health and well-being very well. This is very
                positive, and we would encourage you to keep going.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 9 out of 10 is a very good indicator of your ability
                to enjoy a calmer, happier headspace, whether through minimising
                interactions with stressful situations or being determined and
                proactive in addressing them.
              </p>
            </div>
            <div v-if="message < 9 && message >= 8">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is Good
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are in a GOOD mental
                headspace which is positive.
              </p>
              <p class="pb-1 font-weight-normal">
                It appears you are not totally overwhelmed by stress or negative
                thoughts and beliefs, but when you are, you seem to regain control
                of your thoughts and emotions.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 8 out of 10 is good and we would encourage you to
                continue doing what is working for you.
              </p>
              <p class="pb-1 font-weight-normal">
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
            </div>
            <div v-if="message < 8 && message >= 7">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is Quite Good
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are in QUITE GOOD
                mental headspace, which is positive.
              </p>
              <p class="pb-1 font-weight-normal">
                There may have been occasions where you may have felt a bit low
                and overwhelmed but for most of the time, it appears that you are
                dealing with situations in a reasonable way.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 7 out of 10 is decent but we really want you feeling
                more empowered and in control.
              </p>
              <p class="pb-1 font-weight-normal">
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
            </div>
            <div v-if="message < 7 && message >= 6">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is OK
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are broadly DOING OK
                but sometimes may struggle to cope with your day to day and
                emotional challenges for various reasons.
              </p>
              <p class="pb-1 font-weight-normal">
                It is important to understand that this is normal for everyone. We
                all go through situations and emotions in life that can feel or
                become overwhelming but how we deal with them can be the
                difference between better mental health and consistent mental
                distress.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 6 out of 10 is OK but can certainly be improved.
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
            </div>
            <div v-if="message < 6 && message >= 5">
              <h3 class="pb-1 font-weight-normal text-center">Average</h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, your feelings of stress
                or low mood (or both) may be a more frequent occurrence than you
                are comfortable with.
              </p>
              <p class="pb-1 font-weight-normal">
                It is important to understand that you are not alone even though
                you may sometimes feel like you are. We all go through things in
                life that can be so mentally consuming to the point where we may
                begin to feel helpless or sometimes hopeless (or both).
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 5 out of 10 is average. The important thing to
                remember is that there are things you can do and people you can
                engage with to help you on your way to better, more consistent
                mental health experiences.
              </p>
              <p class="pb-1 font-weight-normal">
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
            </div>
            <div v-if="message < 5 && message >= 4">
              <h3 class="pb-1 font-weight-normal text-center">
                Your overall mental health is Quite Low
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you may be finding it
                hard to deal with your day to day and emotional challenges for
                various reasons.
              </p>
              <p class="pb-1 font-weight-normal">
                We recommend you start making some key adjustments to improve your
                sense of happiness and general well-being. These do not have to be
                drastic by any means but neglecting to act can lead to escalating
                mental distress. kinder to yourself.
              </p>
              <p class="pb-1 font-weight-normal">
                Although your score of 4 out of 10 is quite low, the fact that you
                are here is awesome and shows a very positive, strong and hopeful
                approach to improving your overall mental health. It’s time to be
                kinder to yourself.
              </p>
              <p class="pb-1 font-weight-normal">
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
              <p class="pb-1 font-weight-normal">
                If you feel in a critical, crisis or emergency situation, contact
                -
              </p>
              <p class="pb-1 font-weight-normal">
                <u>Samaritans</u> <br />
                Phone: 116 123 <br />
                (Freephone, 24 hours a day, 7 days a week) <br />
                Email:
                <a
                  href="mailto:jo@samaritans.org?subject=Mental Health Help&amp;"
                  title="Share by Email"
                >
                  jo@samaritans.org
                </a>
                <br />
                Website:
                <a target="_blank" href="https://www.samaritans.org/">
                  www.samaritans.org
                </a>
              </p>
              <p class="pb-1 font-weight-normal">
                Samaritans provide emotional support for people 24 hours a day,
                365 days a year. They allow people to talk about their feelings of
                distress and despair in a safe, confidential and non-judgemental
                supportive way.
              </p>
            </div>
            <div v-if="message < 4 && message >= 3">
              <h3 class="pb-1 font-weight-normal">
                Your overall mental health is Low
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are struggling to
                cope with your day-to-day situations or emotions (or both), and we
                would suggest addressing this as priority.
              </p>
              <p class="pb-1 font-weight-normal">
                We recommend you start making key adjustments to improve your
                sense of happiness and general well-being. Mental distress can
                escalate and become a more serious disability if left unaddressed
                over longer periods of time.
              </p>
              <p class="pb-1 font-weight-normal">
                Your score of 3 out of 10 is low and we would strongly suggest
                taking proactive steps to improve your mental health. Also
                consider contacting your GP (or personal doctor) or submitting a
                self-referral to your local mental health team for further
                support.
              </p>
              <p class="pb-1 font-weight-normal">
                Visit the learn and develop hub for simple yet practical tools and
                resources that have been created by mental health professionals to
                support your goal of achieving and maintaining great mental
                health.
              </p>
              <p class="pb-1 font-weight-normal">
                We feel you may be a critical, crisis or emergency, and would
                recommend you contact -
              </p>
              <p class="pb-1 font-weight-normal">
                <u>Samaritans</u> <br />
                Phone: 116 123 <br />
                (Freephone, 24 hours a day, 7 days a week) <br />
                Email:
                <a
                  href="mailto:jo@samaritans.org?subject=Mental Health Help&amp;"
                  title="Share by Email"
                >
                  jo@samaritans.org
                </a>
                <br />
                Website:
                <a target="_blank" href="https://www.samaritans.org/">
                  www.samaritans.org
                </a>
              </p>
              <p class="pb-1 font-weight-normal">
                Samaritans provide emotional support for people 24 hours a day,
                365 days a year. They allow people to talk about their feelings of
                distress and despair in a safe, confidential and non-judgemental
                supportive way.
              </p>
            </div>
            <div v-if="message < 3 && message >= 2">
              <h3 class="pb-1 font-weight-normal">
                Your overall mental health is Very Low
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are not coping with
                your day-to-day situations or emotions and this is very
                concerning. We can offer advice, suggestions and resources but you
                may need more support through this difficult time.
              </p>
              <p class="pb-1 font-weight-normal">
                You have taken a great proactive step by using mental health check
                to identify the situations that are impacting your mental health
                and now you have more tangible information, you are better
                equipped to begging your journey to better mental health and
                well-being.
              </p>
              <p class="pb-1 font-weight-normal">
                We feel you may be a critical, crisis or emergency, and would
                recommend you contact -
              </p>
              <p class="pb-1 font-weight-normal">
                <u>Samaritans</u> <br />
                Phone: 116 123 <br />
                (Freephone, 24 hours a day, 7 days a week) <br />
                Email:
                <a
                  href="mailto:jo@samaritans.org?subject=Mental Health Help&amp;"
                  title="Share by Email"
                >
                  jo@samaritans.org
                </a>
                <br />
                Website:
                <a target="_blank" href="https://www.samaritans.org/">
                  www.samaritans.org
                </a>
              </p>
              <p class="pb-1 font-weight-normal">
                Samaritans provide emotional support for people 24 hours a day,
                365 days a year. They allow people to talk about their feelings of
                distress and despair in a safe, confidential and non-judgemental
                supportive way.
              </p>
            </div>
            <div v-if="message < 2 && message >= 1">
              <h3 class="pb-1 font-weight-normal">
                Your overall mental health is Extremely Low
              </h3>
              <p class="pb-1 font-weight-normal">
                This score indicates that, based on the mental health checks
                <span v-if="qName"> for {{ qName }}</span>
                <span v-else> you have completed</span>, you are not coping at all
                with your day-to-day situations or emotions, and this is extremely
                concerning. Your score indicates you are really struggling with
                your mental well-being at this present time.
              </p>
              <p class="pb-1 font-weight-normal">
                Whilst we can offer advice, suggestions and resources, we strongly
                advise that you contact the emergency services on 999, go to your
                nearest A&E or call the Samaritans on 116 123 or the NHS on 111 as
                priority. Consider doing this now.
              </p>
              <p class="pb-1 font-weight-normal">
                We also strongly advise that you contact you GP (or personal
                doctor), local mental health support team or any local charities
                that can provide you with the immediate support you need.
              </p>
              <p class="pb-1 font-weight-normal">
                Always remember that mental health (like our physical health) can
                and does get better. The fact that you are here shows your
                commitment and determination to begin your journey to better
                mental health. You deserve this.
              </p>
              <p class="pb-1 font-weight-normal">
                As a secondary option, visit the learn and develop hub for simple
                yet practical tools and resources that have been created by mental
                health professionals to support your goal of achieving and
                maintaining great mental health.
              </p>
              <p class="pb-1 font-weight-normal">
                We feel you may be a critical, crisis or emergency, and would
                recommend you contact -
              </p>
              <p class="pb-1 font-weight-normal">
                <u>Samaritans</u> <br />
                Phone: 116 123 <br />
                (Freephone, 24 hours a day, 7 days a week) <br />
                Email:
                <a
                  href="mailto:jo@samaritans.org?subject=Mental Health Help&amp;"
                  title="Share by Email"
                >
                  jo@samaritans.org
                </a>
                <br />
                Website:
                <a target="_blank" href="https://www.samaritans.org/">
                  www.samaritans.org
                </a>
              </p>
              <p class="py-1 font-weight-normal">
                Samaritans provide emotional support for people 24 hours a day,
                365 days a year. They allow people to talk about their feelings of
                distress and despair in a safe, confidential and non-judgemental
                supportive way.
              </p>
            </div>
          </div>

        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal } from "bootstrap-vue";
import PieChart from '@/components/my-hub/PieChart.vue'
import BarChart from "@/components/my-hub/BarChart.vue";

export default {
  components: {
    BLink,
    BModal,
    PieChart,
    BarChart
  },
  props: ["type", "subcategory", "message", "qName", "circleChartData", "barChartData", "chartColor", "barColors", "professional", "barCategories", "score"],
  data() {
    return {
      name: JSON.parse(localStorage.getItem('userData')).username
    }
  },
  methods: {
    openModal() {
      if (this.$refs["wdtm-modal"] != undefined) {
        this.$refs["wdtm-modal"].show();
      }
    },
  },
};
</script>

<style scoped>
.mhc-link-blue-color {
  color: #245bf0;
}

.rounded-image-100px {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.professional-cloud-opinion {
    border: 1px solid #dfdfdf;
    border-radius: 30px;
    padding: 1.5rem;
}

@media screen and (max-width: 767px) {
    .professional-cloud-opinion {
        border-radius: 20px;
        padding: 10px;
    }

    .rounded-image-100px {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
}

.triangle {
    width: 0;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #dfdfdf;
    position: relative;
}

.triangle:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    left: -20px;
    top: 2px;
}
</style>